<script lang="tsx">
import type { PropType, SlotsType, ComponentInstance } from 'vue'
import { NuxtLink } from '#components'
import type { BreadcrumbItem } from '@core-types/components/CoreUiBreadcrumbs'
import type { ComponentOverrideOptions } from '@core-types/components'

export type CoreUiBreadcrumbsProps<T extends BreadcrumbItem> = {
    items: T[]
    renderLeadingSeparator?: boolean
    collapseMaxItems?: number
    includeRoot?: boolean | string
}

type CoreUiBreadcrumbsSlots<T> = {
    breadcrumb: { item: T, isRoot: boolean }
    separator: { index: number }
    collapsed: { items: T[] }
}

type ComponentOptions = {

}

export function defineComponentCoreUiBreadcrumbs<T extends BreadcrumbItem>(options?: ComponentOverrideOptions<ComponentOptions, CoreUiBreadcrumbsProps<T>, CoreUiBreadcrumbsSlots<T>>) {
    return defineComponent(
        (props: CoreUiBreadcrumbsProps<T>, ctx) => {

            const { t } = useI18n()
            const localePath = useLocalePath()

            const breadcrumbs = computed(() => [
                ...(props.includeRoot ? [{ label: typeof props.includeRoot === 'string' ? props.includeRoot : t('_core_simploshop.labels.home'), to: localePath('/') } as T] : []),
                ...(props.items ?? []),
            ])

            function shouldItemBeCollapsed(index: number) {
                const maxItems = props.collapseMaxItems ? Math.max(2, props.collapseMaxItems) : undefined
                if (maxItems === undefined || maxItems >= breadcrumbs.value.length) {
                    return { shouldCollapse: false, shouldRender: true }
                }

                const numberOfItemsToCollapse = breadcrumbs.value.length - maxItems

                const firstItemToCollapseIdx = 1
                const lastItemToCollapseIdx = firstItemToCollapseIdx + numberOfItemsToCollapse

                return {
                    shouldCollapse: index >= firstItemToCollapseIdx && index < lastItemToCollapseIdx,
                    shouldRender: index <= firstItemToCollapseIdx || lastItemToCollapseIdx <= index,
                }
            }

            return () => (
                <nav aria-label={t('_core_simploshop.accessibility.breadcrumbs')} class="sim-breadcrumbs">
                    <ol class="sim-breadcrumbs__container">
                        {
                            breadcrumbs.value.map((item, index) => {
                                const shouldRenderSeparator = index <= breadcrumbs.value.length - 1 && (index > 0 || props.renderLeadingSeparator)

                                const separator = shouldRenderSeparator
                                    ? (
                                        <li aria-hidden="true" role="presentation">
                                            <span class="sim-breadcrumbs__separator">
                                                {
                                                    renderSlot(ctx.slots.separator, options?.slots?.separator, { index },
                                                        (
                                                            <>
                                                            /
                                                            </>
                                                        )
                                                    )
                                                }
                                            </span>
                                        </li>
                                    )
                                    : null

                                const {
                                    shouldCollapse,
                                    shouldRender,
                                } = shouldItemBeCollapsed(index)

                                // const BreadcrumbComponent = (item.to
                                //     ? resolveComponent('NuxtLink')
                                //     : 'span') as ComponentInstance<any>

                                if (!shouldRender) {
                                    return (
                                        <>
                                            {
                                                null
                                            }
                                        </>
                                    )
                                }

                                if (shouldCollapse) {
                                    return (
                                        <>
                                            { separator }

                                            <li role="presentation">
                                                <span class="sim-breadcrumbs__collapse">
                                                    {
                                                        renderSlot(ctx.slots.collapsed, options?.slots?.collapsed, { items: [item] },
                                                            (
                                                                <>
                                                                ...
                                                                </>
                                                            )
                                                        )
                                                    }
                                                </span>
                                            </li>
                                        </>
                                    )
                                }

                                if (!item.to) {
                                    return (
                                        <>
                                            { separator }

                                            <li>
                                                <span
                                                    class={[
                                                        'sim-breadcrumbs__item',
                                                        {
                                                            'sim-breadcrumbs__item--active': index === breadcrumbs.value.length - 1,
                                                        },
                                                    ]}>
                                                    {
                                                        renderSlot(ctx.slots.breadcrumb, options?.slots?.breadcrumb, {
                                                            item: item,
                                                            isRoot: !!(props.includeRoot && index === 0),
                                                        },
                                                        (
                                                            <>
                                                                { 'label' in item ? `${item.label}` : `${item}` }
                                                            </>
                                                        )
                                                        )
                                                    }
                                                </span>
                                            </li>
                                        </>
                                    )
                                }

                                return (
                                    <>
                                        {separator}

                                        <li>
                                            <NuxtLink
                                                class={[
                                                    'sim-breadcrumbs__item',
                                                    {
                                                        'sim-breadcrumbs__item--active': index === breadcrumbs.value.length - 1,
                                                    },
                                                ]}
                                                to={item.to ?? undefined}
                                            >
                                                {
                                                    renderSlot(ctx.slots.breadcrumb, options?.slots?.breadcrumb, {
                                                        item: item,
                                                        isRoot: !!(props.includeRoot && index === 0),
                                                    },
                                                    () => ('label' in item ? `${item.label}` : `${item}`)
                                                    )
                                                }
                                            </NuxtLink>
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ol>
                </nav>
            )
        },
        {
            props: {
                items: {
                    type: Array as PropType<CoreUiBreadcrumbsProps<T>['items']>,
                    required: true,
                },
                renderLeadingSeparator: {
                    type: Boolean as PropType<CoreUiBreadcrumbsProps<T>['renderLeadingSeparator']>,
                    default: options?.props?.renderLeadingSeparator?.default ?? false,
                    required: options?.props?.renderLeadingSeparator?.required ?? false,
                },
                collapseMaxItems: {
                    type: Number as PropType<CoreUiBreadcrumbsProps<T>['collapseMaxItems']>,
                    default: options?.props?.collapseMaxItems?.default ?? undefined,
                    required: options?.props?.collapseMaxItems?.required ?? false,
                },
                includeRoot: {
                    type: [Boolean, String] as PropType<CoreUiBreadcrumbsProps<T>['includeRoot']>,
                    default: options?.props?.includeRoot?.default ?? false,
                    required: options?.props?.includeRoot?.required ?? false,
                },
            },
            slots: Object as SlotsType<CoreUiBreadcrumbsSlots<T>>,
            emits: {

            },
        }
    )
}

export default defineComponentCoreUiBreadcrumbs()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiBreadcrumbs" as *;

</style>
